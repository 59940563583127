import React from 'react';

const ModalCheck = ({ clickOff, error, taskInspect, task }) => {
  if (
    task &&
    ['01', '08'].includes(taskInspect.photoCode) &&
    error === false &&
    task.message !== 'Success!'
  ) {
    return (
      <div className={`modal check show-modal`} id="myModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Inspection!</h4>
            </div>

            <div className="modal-body">
              <p>VinDecode Wrong Plz Input Again</p>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className={`btn btn-primary `}
                onClick={clickOff}
              >
                Try Again
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ModalCheck;
