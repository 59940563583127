import React from 'react';
const InfoBar = (props) => {
  const {
    vehicle,
    taskInspect,
    imgSupport,
    support,
    inspectionInspect,
    inspectName,
    auth,
  } = props;
  const photoCode =
    taskInspect && taskInspect.photoCode ? taskInspect.photoCode : '';
  const vehicleData = vehicle && vehicle.data ? vehicle.data : null;
  const inspectionID =
    inspectionInspect && inspectionInspect.inspectionID
      ? inspectionInspect.inspectionID
      : null;
  const sessionId =
    inspectionInspect && inspectionInspect.sessionID
      ? inspectionInspect.sessionID
      : null;
  const timezone =
    auth && auth.user && auth.user.options && auth.user.options.timezone
      ? auth.user.options.timezone
      : 'Asia/Ho_Chi_Minh';
  const qc_on_having_odometer =
    inspectionInspect &&
    inspectionInspect.options &&
    inspectionInspect.options.qc_on_having_odometer
      ? inspectionInspect.options.qc_on_having_odometer
      : false;
  let checkLeftRight = () => {
    let src = '';
    let sameInspectionFinished = {};
    if (inspectionInspect && inspectionInspect.sameInspection) {
      const newTime = inspectionInspect.sameInspection.finishedAt
        ? new Date(inspectionInspect.sameInspection.finishedAt)
        : new Date(inspectionInspect.sameInspection.updatedAt);
      sameInspectionFinished = newTime.toLocaleString('en-US', {
        timeZone: timezone,
      });
    }
    switch (photoCode) {
      case '01':
        src = '/img/outline/vin.svg';
        break;
      case '02':
        src = '/img/outline/interior.svg';
        break;
      case '03':
        src = '/img/outline/odo.svg';
        break;
      case '04':
        src = '/img/outline/left.svg';
        break;
      case '05':
        src = '/img/outline/front.svg';
        break;
      case '06':
        src = '/img/outline/tireFront.svg';
        break;
      case '07':
        src = '/img/outline/right.svg';
        break;
      case '08':
        src = '/img/outline/rear.svg';
        break;
      case '09':
        src = '/img/outline/glass.svg';
        break;
      case '10':
        src = '/img/outline/10.svg';
        break;
      case '11':
        src = '/img/outline/11.svg';
        break;
      case '12':
        src = '/img/outline/12.svg';
        break;
      case '13':
        src = '/img/outline/13.svg';
        break;
      case '15':
        src = '/img/outline/tireRear.svg';
        break;
      default:
        break;
    }
    return (
      <div className="image-svg" style={{ position: 'relative' }}>
        {inspectionInspect && inspectionInspect.sameInspection ? (
          <div>
            <div
              className="badge badge-danger text-white text-head mt-1 d-block"
              style={{
                fontSize: '16px',
                position: 'absolute',
                top: '-12px',
                right: '0px',
                zIndex: 99,
              }}
            >
              <span>
                Data Same Vin
                <i className="fas fa-image ml-1" style={{ color: 'green' }}></i>
                (C)
              </span>
            </div>
            <div
              className="badge badge-primary text-white text-head mt-1 d-block"
              style={{
                fontSize: '16px',
                position: 'absolute',
                top: '20px',
                right: '0px',
                zIndex: 99,
              }}
            >
              <i className="fas fa-clock"></i>
              <span>{sameInspectionFinished}</span>
            </div>
          </div>
        ) : null}
        <div className="group-image">
          <img src={src} alt=""></img>
        </div>
      </div>
    );
  };
  const warningOdo = () => {
    const d = new Date();
    const year = d.getFullYear();
    const vehicleYear = vehicle && vehicle.year ? parseInt(vehicle.year) : 9999;

    if (year - vehicleYear >= 2) {
      return (
        <div
          className="alert alert-warning text-dark text-head"
          style={{ fontSize: '15px', fontWeight: 'bold' }}
        >
          <i className="fas fa-exclamation-triangle"></i>
          <span>
            Warning: Vehicle Year is{' '}
            {vehicle && vehicle.year ? vehicle.year : ''}, Odometer Reading will
            mostly be {`>`} 10,000 km
          </span>
        </div>
      );
    } else {
      return null;
    }
  };
  const dataInspected = () => {
    const dataShow = [];
    const inspectData =
      taskInspect && taskInspect.inspectData ? taskInspect.inspectData : null;
    if (inspectData) {
      if (inspectData.odom_reading) {
        dataShow.push({
          name: 'Odom Reading',
          descrip: inspectData.odom_reading,
        });
      }

      if (inspectData.odom_unit) {
        dataShow.push({
          name: 'Odom Unit',
          descrip: inspectData.odom_unit,
        });
      }
    }
    return dataShow.length > 0 ? (
      <div
        className="alert alert-success text-dark mt-1 text-head"
        style={{ fontWeight: 'bold' }}
      >
        <i className="fas fa-edit mb-2"></i>
        <span style={{ fontSize: '15px' }}>Data Inspected</span>
        {dataShow.map((item, index) => {
          return (
            <div
              key={`ref_${index}`}
              style={{ fontSize: '15px' }}
              className="d-flex mb-1"
            >
              <div className="badge badge-dark text-head text-uppercase mr-2">
                {item.name}
              </div>
              <div>{item.descrip}</div>
            </div>
          );
        })}
      </div>
    ) : null;
  };
  const renderReference = () => {
    let dataShow = [];
    switch (inspectName) {
      case 'qc_interior':
      case 'interior':
        if (vehicleData && vehicleData.int_col) {
          dataShow.push({
            name: 'Interior Color',
            descrip: vehicleData.int_col,
          });
        }
        if (vehicleData && vehicleData.transmission) {
          dataShow.push({
            name: 'Transmission',
            descrip: vehicleData.transmission,
          });
        }
        break;
      case 'ext_id':
        if (vehicleData && vehicleData.ext_col) {
          dataShow.push({
            name: 'Exterior Color',
            descrip: vehicleData.ext_col,
          });
        }

        break;
      case 'trim_id':
        if (vehicleData && vehicleData.trim) {
          dataShow.push({
            name: 'Trim',
            descrip: vehicleData.trim,
          });
        }
        break;
      case 'qc_odom':
      case 'odom':
        if (vehicleData && vehicleData.odom_reading) {
          dataShow.push({
            name: 'Odom Reading',
            descrip: vehicleData.odom_reading,
          });
        }
        if (vehicleData && vehicleData.odom_unit) {
          dataShow.push({
            name: 'Odom Unit',
            descrip: vehicleData.odom_unit,
          });
        }
        break;
      default:
        break;
    }
    if (dataShow && dataShow.length > 0) {
      return (
        <div
          className="alert alert-primary text-dark mt-1 text-head"
          style={{ fontWeight: 'bold' }}
        >
          <i className="fas fa-edit mb-2"></i>
          <span style={{ fontSize: '15px' }}>Reference Vehicle</span>
          {dataShow.map((item, index) => {
            return (
              <div
                key={`ref_${index}`}
                style={{ fontSize: '15px' }}
                className="d-flex mb-1"
              >
                <div className="badge badge-dark text-head text-uppercase mr-2">
                  {item.name}
                </div>
                <div>{item.descrip}</div>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };
  return (
    <div className="infobar">
      <ul className="detail-car">
        <li>
          <div>
            <div className="badge badge-dark text-head text-uppercase mr-1">
              Id
            </div>
            <span>
              {sessionId} / {inspectionID}
            </span>
          </div>
          <div className="badge badge-dark text-head text-uppercase mr-1">
            Support
          </div>
          {imgSupport.length > 0
            ? imgSupport.map((item, index) => {
                return (
                  <div
                    key={`${index}_support`}
                    className="img mr-1"
                    style={{
                      display: 'inline-block',
                      width: '35px',
                      height: '15px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      opacity:
                        support.index && support.index === index + 1
                          ? '1'
                          : '0.5',
                    }}
                  >
                    <img
                      style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '15px',
                      }}
                      src={`${item}`}
                      alt="img-sup"
                    ></img>
                  </div>
                );
              })
            : 'No Support'}
        </li>
      </ul>
      {checkLeftRight()}
      {photoCode === '03' ? warningOdo() : null}
      {renderReference()}
      {vehicle && vehicle.vrm && photoCode === '08' && (
        <div className="group-info">
          <div className="badge badge-dark text-head text-uppercase mr-1">
            PLate Number
          </div>
          <span>{vehicle.vrm}</span>
          <span></span>
        </div>
      )}
      {photoCode === '03' && qc_on_having_odometer ? dataInspected() : null}
    </div>
  );
};

export default InfoBar;
